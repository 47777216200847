import React from "react"
import { Typography, Breadcrumb} from 'antd';
import MainLayout from "../components/layout/MainLayout";
import SEO from "../components/seo";

const { Title, Text } = Typography;


export default () => {

    let breadcrumbItems = [(<Breadcrumb.Item key="about-us">About us</Breadcrumb.Item>)]
    return (
        <MainLayout breadcrumbItems={breadcrumbItems}>
            <SEO title={`About us`} description={`Who are we at MrLTactics.com.`} />
            <Title>About us</Title>
            <Text>Page under construction</Text>
        </MainLayout>
    )
};
